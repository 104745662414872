<template>
  <div>
    <show-invoices ref="ShowInvoicesDetail" />
  </div>
</template>
<script>

  export default {
    components: {
      ShowInvoices: () => import('./components/ShowInvoicesDetail'),
    },
  }
</script>
